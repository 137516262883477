<template>
  <a-modal
    id="import-file-modal"
    v-model:visible="showImportModal"
    centered
    :title="selectedFile?.fileName"
    :body-style="{
      height: '25vh',
    }"
  >
    <template #closeIcon>
      <close-outlined id="import-file-modal-close-btn" />
    </template>
    <a-list
      v-if="metaFile.length > 0"
      id="import-meta-file-list"
      item-layout="horizontal"
      :data-source="metaFile"
    >
      <template #renderItem="{ item, index }">
        <a-list-item>
          <template #actions>
            <delete-outlined
              :id="'delete-import-meta-file-' + index"
              class="text-danger"
              @click="metaFile = []"
            />
          </template>
          <a-list-item-meta
            :id="'import-meta-file-' + index + '-details'"
            :title="item.name"
            :description="(item.size / 1024).toFixed(2) + ' KB'"
          />
        </a-list-item>
      </template>
    </a-list>
    <template v-else>
      <input
        id="hidden-upload-file-input"
        ref="uploadInput"
        type="file"
        hidden
        @change="handleChange"
      />
      <div
        id="import-file-drop-area"
        class="drop-area"
        @click="uploadInput.click"
        @drop="handleFileDrop"
        @dragenter.prevent
        @dragover.prevent
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined />
        </p>
        <p class="ant-upload-text">
          Click or drag meta file to this area to upload
        </p>
      </div>
    </template>

    <template #footer>
      <div class="w-100 text-center">
        <a-button
          id="upload-meta-file-btn"
          type="primary"
          :loading="isUploadingMetaFile"
          :disabled="!metaFile.length"
          @click="handleImportMetaFile(selectedFile, fileMetaData)"
        >
          <template #icon>
            <upload-outlined />
          </template>
          Upload
        </a-button>
      </div>
    </template>
  </a-modal>
  <a-table
    id="label-data-videos-table"
    :data-source="video_list"
    :columns="tableColumns"
    :loading="loading || isMetaFilesBeingUpdated"
    :pagination="{
      ...pagination,
      showLessItems: false, // Show all pages instead of collapsing
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100', '200'],
      showQuickJumper: true,
    }"
    class="m-0"
    :scroll="{
      x: true,
    }"
    @change="handleTableChange"
  >
    <template #title>
      <div class="d-flex justify-content-between">
        <a-typography-title :level="5">
          Videos Recorded: &nbsp;
          <a-tag id="label-data-videos-count" color="blue">
            {{ videosCount }}
          </a-tag>
        </a-typography-title>

        <a-button
          type="danger"
          :disabled="isDeleteDisabled"
          @click="handleDeleteAll"
          :loading="state.loadingDelete"
          >Delete All</a-button
        >
      </div>
    </template>

    <template #headerCell="{ title }">
      <span
        :id="'ld-' + title + '-header-cell'"
        class="d-flex justify-content-center"
      >
        {{ title }}
      </span>
    </template>
    <template
      #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }"
    >
      <div id="videos-custom-filter-dropdown" style="padding: 8px">
        <a-input
          ref="searchInput"
          v-model:value="state.searchText"
          :placeholder="`Search ${column.dataIndex}`"
          style="width: 188px; margin-bottom: 8px; display: block"
          @pressEnter="handleSearch(confirm)"
        />
        <a-button
          type="primary"
          size="small"
          class="mr-1"
          style="width: 90px"
          :disabled="!video_list.length"
          @click="handleSearch(confirm)"
        >
          <template #icon>
            <SearchOutlined />
          </template>
          Search
        </a-button>
        <a-button size="small" style="width: 90px" @click="handleReset()">
          Reset
        </a-button>
      </div>
    </template>

    <template #customFilterIcon="{ filtered }">
      <search-outlined
        :style="{
          color:
            filtered || state.searchText || state.searchText
              ? '#108ee9'
              : undefined,
        }"
      />
    </template>

    <template #bodyCell="{ text, record, column, index }">
      <span
        v-if="column.dataIndex === 'thumbnail_path'"
        id="ld-video-tumbnail-cell"
        class="d-flex"
      >
        <img
          v-if="record.isThumbnailExist"
          class="clickable"
          :src="thumbnailUrl(record)"
          :alt="getFileName(record.fileName)"
          width="140"
          height="124"
          @click="emit('handlePlay', record)"
          @error="
            $emit('updateVideo', record.fileName, { isThumbnailExist: false })
          "
        />
        <NotFoundImage v-else :alt-url="thumbnailUrl(record)" />
        <!-- <loading-outlined v-else class="m-auto" /> -->
      </span>

      <span v-if="column.dataIndex === 'fileName'" id="ld-video-filename-cell">
        <a-tooltip>
          <template #title>{{ text }}</template>
          <!-- {{ getFileName(text) }} -->
          {{ text }}
        </a-tooltip>
      </span>

      <span v-if="column.dataIndex === 'labelled'" id="ld-video-labelled-cell">
        <a-progress
          type="circle"
          :percent="getPercent(text)"
          status="normal"
          :width="50"
        />
      </span>

      <span
        v-if="column.dataIndex === 'details'"
        id="ld-video-details-cell"
        class="d-flex flex-wrap"
        style="gap: 4px 0px"
      >
        <a-tag color="blue">Resolution: {{ record.resolution }}</a-tag>
        <a-tag color="blue">FPS: {{ record.fps }}</a-tag>
        <a-tag :color="getColor(record.is_human_in_video)">
          <template #icon>
            <i
              class="bi bi-file-person-fill"
              :class="{
                'text-secondary': !record.is_human_in_video,
              }"
            />
          </template>
        </a-tag>
        <a-tag
          v-if="isTask3D"
          :color="record.is_depth_file_available ? 'blue' : 'default'"
          >Depth: {{ record.is_depth_file_available ? 'Yes' : 'No' }}
        </a-tag>
        <a-tag :color="getColorTags(record.tag_count)">
          <template #icon>
            <span v-if="record.entity_id"
              >{{ record.no_of_comments }}&nbsp;</span
            ><CommentOutlined />
          </template>
        </a-tag>
      </span>

      <span
        v-if="column.dataIndex === 'operation'"
        id="ld-video-operation-cell"
      >
        <a-button
          id="operation-annotation-video-btn"
          type="primary"
          block
          :loading="record.loadingAnnotation"
          @click="emit('annotateVideo', record)"
        >
          Annotate
        </a-button>
        <!-- <a-button
          v-if="isVideoLonger(record.duration)"
          id="operation-trim-video-btn"
          type="primary"
          block
          class="mt-2"
          @click="emit('trimVideo', record)"
        >
          Trim
        </a-button> -->
      </span>

      <span v-if="column.dataIndex === 'created_at'" id="ld-video-date-cell">
        <small>
          {{ formatDate(text) }}
        </small>
      </span>

      <span
        v-if="column.dataIndex === 'actions'"
        :id="'ld-video-actions-cell-' + index"
      >
        <a-space :size="20">
          <a-dropdown :trigger="['click']" placement="bottomRight">
            <template #overlay>
              <a-menu :id="'ld-video' + index + '-menu'">
                <a-menu-item
                  id="video-comment-menu-item"
                  @click="fetchComments(record)"
                >
                  <CommentOutlined />
                  Comments
                </a-menu-item>
                <a-menu-item
                  id="video-tags-menu-item"
                  @click="fetchTags(record)"
                >
                  <TagsOutlined />
                  Tags
                </a-menu-item>

                <a-menu-item
                  id="video-download-menu-item"
                  @click="handleDownloadVideo(record)"
                >
                  <DownloadOutlined />
                  Download
                </a-menu-item>
                <a-menu-item
                  id="video-download-menu-item"
                  :loading="record.loadingAnnotation"
                  @click="emit('annotateLabellingVideo', record)"
                >
                  <EditOutlined />
                  Label Video
                </a-menu-item>
                <a-menu-item
                  id="video-import-menu-item"
                  @click="
                    () => {
                      selectedFile = record;
                      showImportModal = true;
                    }
                  "
                >
                  <ImportOutlined />
                  Import Meta File
                </a-menu-item>
                <a-menu-item
                  id="video-export-menu-item"
                  :disabled="!record.labelled"
                  @click="emit('handleExportMetaFile', record)"
                >
                  <ExportOutlined />
                  Export Meta File
                </a-menu-item>
                <a-divider class="my-1" />

                <a-popconfirm
                  id="video-delete-menu-item"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="emit('deleteVideo', record)"
                  @cancel="recordToDelete = null"
                >
                  <template #title>
                    <span>
                      Do you want to delete the video
                      <strong>
                        {{ record.fileName }}
                      </strong>
                      ?
                      <br />
                      This will delete all associated labels as well.
                    </span>
                  </template>
                  <template #icon>
                    <QuestionCircleOutlined style="color: red" />
                  </template>
                  <a-menu-item class="text-danger">
                    <DeleteOutlined />
                    Delete
                  </a-menu-item>
                </a-popconfirm>
              </a-menu>
            </template>
            <a-button :id="'ld-video-' + index + '-menu-btn'">
              <template #icon>
                <MenuOutlined />
              </template>
            </a-button>
          </a-dropdown>
          <a-checkbox
            v-model:checked="state.deleteCheckbox[record.id]"
          ></a-checkbox>
        </a-space>
      </span>
    </template>
  </a-table>
</template>

<script>
import { downloadFileUsingUrl } from '../../../shared/Helpers/downLoadFIleUsingUrl';
import dateHelper from '../../../shared/Helpers/dateHelper';
import {
  computed,
  defineComponent,
  inject,
  reactive,
  ref,
  toRefs,
  watch,
  watchPostEffect,
} from 'vue';
import {
  SearchOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  LoadingOutlined,
  MenuOutlined,
  CommentOutlined,
  TagsOutlined,
  ImportOutlined,
  ExportOutlined,
  InboxOutlined,
  UploadOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import httpClient from 'src/service/httpClient';
import { POSITION } from 'vue-toastification';
import { tableColumns } from './config';
import { trainingThumbnail, getThumbnailUrl } from 'src/utils/thumbnail.js';
import ThumbnailService from 'src/services/thumbnails';
import VideoService from 'src/services/videos';
import NotFoundImage from 'src/components/shared/Components/NotFound.vue';

export default defineComponent({
  components: {
    SearchOutlined,
    DownloadOutlined,
    EditOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    LoadingOutlined,
    MenuOutlined,
    CommentOutlined,
    TagsOutlined,
    ImportOutlined,
    ExportOutlined,
    InboxOutlined,
    UploadOutlined,
    CloseOutlined,
    NotFoundImage,
  },
  props: {
    video_list: {},
    videosCount: {},
    taskId: {},
    taskName: {},
    sortBy: {},
    sortOrder: {},
    loading: {},
    isMetaFilesBeingUpdated: {},
    canDownload: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canUpdateProtection: {
      type: Boolean,
      default: true,
    },
    labeler: {
      type: Boolean,
      default: false,
    },
    isTask3D: { type: Boolean, default: false },
    getVideoS3Details: { type: Function, default: () => {} },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  emits: [
    'updateVideo',
    'handlePlay',
    'annotateVideo',
    'annotateLabellingVideo',
    'handleExportMetaFile',
    'trimVideo',
    'sortList',
    'deleteVideo',
    'updateTaskRecord',
    'pageChange',
    'openObjectAnnotationModal',
    'showCommentsModal',
    'showTagsModal',
    'setClearSearch',
    'bulkDelete',
  ],

  setup(props, { emit }) {
    const state = reactive({
      pageSize: 10,
      current: 1,
      searchText: '',
      deleteCheckbox: {},
      loadingDelete: false,
    });
    const searchInput = ref();
    const isThumbnailExist = ref(true);
    const showImportModal = ref(false);
    const metaFile = ref([]);
    const queuedthumbnailGenerations = ref([]);
    const fileMetaData = ref(null);
    const selectedFile = ref(null);
    const uploadInput = ref(null);
    const isUploadingMetaFile = ref(false);
    const toast = inject('toast');
    const organization = localStorage.getItem('organization');
    const { query } = useRoute();

    if (query.page) {
      state.current = query.page;
      state.searchText = query.search ? query.search : '';
    }

    watch(
      () => props.taskId,
      () => {
        state.current = 1;
        state.searchText = '';
      }
    );

    watch(
      () => props.currentPage,
      (newPage) => {
        state.current = newPage;
      }
    );

    watchPostEffect(() => {
      props.video_list.forEach((video) => {
        if (!video.isThumbnailExist && !isThumbnailGenerationInitiated(video)) {
          queuedthumbnailGenerations.value.push(video.id);
          handleThumbnailGeneration(video);
        }
      });
    });

    const isDeleteDisabled = computed(() => {
      const deleteCheckboxes = Object.values(state.deleteCheckbox);
      return (
        deleteCheckboxes.length === 0 ||
        !deleteCheckboxes.some((value) => value)
      );
    });

    const handleSearch = async (confirm) => {
      confirm();
      state.current = 1;
      handlePageChange(
        { current: state.current },
        state.pageSize,
        state.searchText
      );
    };

    const handleReset = () => {
      state.searchText = '';
      handlePageChange({ current: state.current });
    };

    const pagination = computed(() => ({
      total: props.videosCount,
      current:
        query?.page > Math.ceil(props.videosCount / state.pageSize)
          ? 1
          : Number(state.current),
      pageSize: state.pageSize,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100', '200'],
      position: ['bottomCenter'],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, // Add total display
      showLessItems: false, // Show more pagination items
      onShowSizeChange: (current, size) => {
        state.pageSize = size;
        state.current = 1; // Reset to first page
      },
    }));

    function isThumbnailGenerationInitiated(video) {
      return queuedthumbnailGenerations.value.includes(video.id);
    }

    async function handleThumbnailGeneration(video) {
      const [error, data] = await generateThumbnail(video);
      if (error) {
        return;
      }
      emit('updateVideo', video.fileName, { isThumbnailExist: true });
      queuedthumbnailGenerations.value =
        queuedthumbnailGenerations.value.filter((id) => id !== video.id);
    }

    async function generateThumbnail(record) {
      const payload = {
        id: record.id,
      };
      return await ThumbnailService.generateThumbnail(payload);
    }

    function handleTableChange(page, filters, sorter) {
      // if (page.current !== Number(state.current)) {
      handlePageChange(page, state.pageSize);
      // }
      if (Object.keys(sorter)?.length) handleSorting(sorter);
    }

    function handleSorting(sorter) {
      let { field, order } = sorter;
      if (!order) {
        field = 'id';
        order = 'ascend';
      }
      emit('sortList', { field, order });
    }

    function handlePageChange(pag, pageSize, filterValue = state.searchText) {
      const { current } = pag;
      state.current = pag.current;
      state.pageSize = pageSize;
      emit('pageChange', {
        page: current,
        pageSize: pageSize,
        filterValue: filterValue,
      });
    }

    function thumbnailUrl(record) {
      const taskName = props.taskName;
      if (!taskName) return;
      const thumbnailPath = trainingThumbnail(record, taskName, organization);
      return getThumbnailUrl(thumbnailPath);
    }

    function getFileName(filename) {
      const length = 50;
      let name = filename?.slice(0, length);
      if (filename?.length > length) name += '...';
      return name;
    }

    function updateVideoProtection(value, video) {
      emit('updateTaskRecord', { isProtectedVideo: value }, video);
    }

    function getPercent(text) {
      return Number(text.toFixed(1));
    }

    function isVideoLonger() {
      return true;
    }

    function getColor(is_human_in_video) {
      if (is_human_in_video) {
        return 'success';
      }
      return 'default';
    }
    function getColorTags(tag_count) {
      if (tag_count > 0) {
        return 'success';
      }
      return 'default';
    }

    async function fetchComments(record) {
      if (!record.entity_id) {
        emit('showCommentsModal', {
          comments: [],
          taskRecord: record,
        });
      } else {
        const comment_response = await httpClient.get(
          `organization/task_record/comments?entity_id=${record.entity_id}`
        );

        emit('showCommentsModal', {
          taskRecord: record,
          comments: comment_response,
        });
      }
    }

    async function fetchTags(record) {
      //predefined tags available for particular organization
      const orgTag_response = await httpClient.get(
        `organization/task_record/tags?organization_id=${localStorage.getItem(
          'organization'
        )}`
      );

      if (!record.entity_id) {
        emit('showTagsModal', {
          taskRecord: record,
          orgTags: orgTag_response,
          entityTag: [],
        });
      } else {
        //tags that are on current entity
        const entityTag_response = await httpClient.get(
          `organization/entity/tags?entity_id=${record.entity_id}`
        );

        emit('showTagsModal', {
          taskRecord: record,
          orgTags: orgTag_response,
          entityTag: entityTag_response,
        });
      }
    }

    function handleChange(event) {
      const [file] = event.target.files;
      metaFile.value = [file];
      var reader = new FileReader();
      reader.onload = onReaderLoad;
      reader.readAsText(file);
    }

    function handleFileDrop(e) {
      e.preventDefault();
      const [file] = e.dataTransfer.files;
      metaFile.value = [file];
      var reader = new FileReader();
      reader.onload = onReaderLoad;
      reader.readAsText(file);
    }

    function onReaderLoad(event) {
      const { result } = event.target;
      fileMetaData.value = result.replaceAll(
        'Infinity',
        Number.MAX_SAFE_INTEGER
      );
    }

    function getPayload(fileMetaData, record, props) {
      var blob = new Blob([fileMetaData], { type: 'text/json;charset=utf-8' });
      var formData = new FormData();
      formData.append('file', blob, record?.fileName + '.json');
      formData.append('task_id', props.taskId);
      formData.append('Validation', 'False');
      return formData;
    }

    function handleResponse(record, response) {
      if (response === 'error') return;
      toast.success('Successfully uploaded meta file!', {
        timeout: 3000,
        position: POSITION.TOP_LEFT,
      });
      emit('updateVideo', record.fileName, Object.values(response.data)[0][0]);
    }

    async function handleImportMetaFile(record, fileMetaData) {
      isUploadingMetaFile.value = true;
      var payload = getPayload(fileMetaData, record, props);
      const response = await httpClient.upload(
        'organization/task/upload/',
        payload
      );
      isUploadingMetaFile.value = false;
      showImportModal.value = false;
      metaFile.value = [];
      handleResponse(record, response);
    }

    async function handleDownloadVideo(record) {
      let fileUrl = record.fileURL;

      if (!fileUrl) {
        const obj = this.getVideoS3Details(record);
        const [error, data] = await VideoService.getPresignedUrl({
          bucket_name: obj.bucket,
          object_path: obj.filePath,
        });
        if (error) {
          toast.error('Error occured while fetching url!', {
            timeout: 3000,
          });
        }
        fileUrl = data?.presigned_url;
        emit('updateVideo', record.fileName, { fileURL: data?.presigned_url });
      }
      downloadFileUsingUrl(fileUrl);
    }

    async function handleDeleteAll() {
      let deleteList = [];
      state.loadingDelete = true;
      Object.entries(state.deleteCheckbox).forEach(([key, value]) => {
        if (value) deleteList.push(key);
      });
      const payload = {
        ids: deleteList,
      };
      const [error] = await VideoService.deleteAllVideos(payload, true);
      if (error) {
        state.loadingDelete = false;
        return toast.error('Error occured while deleting videos!', {
          timeout: 3000,
        });
      }
      state.deleteCheckbox = {};
      state.loadingDelete = false;
      state.current = 1;
      emit('bulkDelete');
    }

    return {
      emit,
      state,
      tableColumns,
      metaFile,
      getColor,
      getColorTags,
      fetchTags,
      getPercent,
      pagination,
      uploadInput,
      handleReset,
      searchInput,
      getFileName,
      selectedFile,
      handleSearch,
      handleChange,
      queuedthumbnailGenerations,
      isThumbnailGenerationInitiated,
      handleThumbnailGeneration,
      generateThumbnail,
      fileMetaData,
      thumbnailUrl,
      isVideoLonger,
      fetchComments,
      handleFileDrop,
      showImportModal,
      ...toRefs(state),
      handleTableChange,
      isUploadingMetaFile,
      handleImportMetaFile,
      handleDownloadVideo,
      updateVideoProtection,
      formatDate: dateHelper.formatDate,
      isThumbnailExist,
      isDeleteDisabled,
      handleDeleteAll,
    };
  },
});
</script>

<style scoped>
td {
  color: black;
  text-align: center;
}

.sort-icon {
  font-size: 12px;
  margin-right: 3px;
}

.video-play {
  width: 100px;
  height: 60px;
}

.table-header-sticky {
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.cursor-p {
  cursor: pointer;
}

.table-container {
  height: 70vh;
}

.drop-area {
  height: 100%;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ant-upload-drag-icon {
  color: #40a9ff;
  font-size: 48px;
}
</style>
